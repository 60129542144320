import {
	Button,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	ListSubheader,
	MenuItem,
	Paper,
	Select,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import TradeRow from './TradeRow';
import { MenuContext, TradesContext, UserContext } from '../App';
import {
	convertDateToRelevant,
	isNumericString,
	translateCategoryToDaySwing,
	translateStatusToText,
	translateUserIdToName,
} from '../lib/helpFunc';
import { createChart } from 'lightweight-charts';
import { LinechartComponent } from './LinechartComponent';
import {
	getAvanzaChartDataViaProxy,
	getLivePriceOnSearchedSymbol,
	newTrade,
	productPriceLive,
	searchSymbols,
} from '../lib/network';

const calcTheoGain = (entry, target) => {
	if (entry && target) {
		try {
			const entryPrice = entry;
			const exitPrice = target;
			const g = ((exitPrice - entryPrice) / entryPrice) * 100;
			if (Math.abs(g) > 20) {
				return Number(g).toFixed(0) + '%';
			} else {
				return Number(g).toFixed(2) + '%';
			}
		} catch (e) {
			return '';
		}
	} else {
		return '';
	}
};

const submitNewTrade = async (
	tradeValues,
	tradeComment,
	setIsNewTradeMenuOpen,
	currentlyOpenSize
) => {
	try {
		const requiredMissingValues = [];
		const wantedMissingValues = [];
		const errorValues = [];
		if (tradeValues.instrument === '') {
			requiredMissingValues.push('Instrument');
		}
		if (tradeValues.type === '') {
			requiredMissingValues.push('Instrumenttyp');
		}
		if (tradeValues.isin === '') {
			requiredMissingValues.push('ISIN');
		}
		if (tradeValues.size === null) {
			requiredMissingValues.push('Size');
		}
		if (tradeValues.entry === null) {
			requiredMissingValues.push('Entry');
		}
		if (
			tradeValues.tradeImageLink === null ||
			tradeValues.tradeImageLink === '' ||
			tradeValues.tradeImageLink.length < 5 ||
			!tradeValues.tradeImageLink.includes('http')
		) {
			requiredMissingValues.push('Analysbild');
		}
		if (
			tradeComment === '' ||
			tradeComment === null ||
			tradeComment.length < 5
		) {
			requiredMissingValues.push('Kommentar');
		}

		if (tradeValues.sl === null) {
			wantedMissingValues.push('SL');
		}
		if (tradeValues.tp1 === null) {
			wantedMissingValues.push('TP1');
		}

		if (!isNumericString(tradeValues.size)) {
			errorValues.push('Size');
		}
		if (!isNumericString(tradeValues.entry)) {
			errorValues.push('Entry');
		}
		if (!isNumericString(tradeValues.sl)) {
			errorValues.push('SL');
		}
		if (tradeValues.tp1 && !isNumericString(tradeValues.tp1)) {
			errorValues.push('TP1');
		}
		if (tradeValues.tp2 && !isNumericString(tradeValues.tp2)) {
			errorValues.push('TP2');
		}
		if (tradeValues.tp3 && !isNumericString(tradeValues.tp3)) {
			errorValues.push('TP3');
		}
		if (!isNumericString(tradeValues.leverage)) {
			errorValues.push('Leverage');
		}

		if (
			tradeValues.underlyingEntry &&
			!isNumericString(tradeValues.underlyingEntry)
		) {
			errorValues.push('Underliggande Entry');
		}
		if (
			tradeValues.underlyingSl &&
			!isNumericString(tradeValues.underlyingSl)
		) {
			errorValues.push('Underliggande SL');
		}
		if (
			tradeValues.underlyingTp1 &&
			!isNumericString(tradeValues.underlyingTp1)
		) {
			errorValues.push('Underliggande TP1');
		}
		if (
			tradeValues.underlyingTp2 &&
			!isNumericString(tradeValues.underlyingTp2)
		) {
			errorValues.push('Underliggande TP2');
		}
		if (
			tradeValues.underlyingTp3 &&
			!isNumericString(tradeValues.underlyingTp3)
		) {
			errorValues.push('Underliggande TP3');
		}
		var underlyingMessage = '';
		if (
			tradeValues.underlyingEntry ||
			tradeValues.underlyingSl ||
			tradeValues.underlyingTp1 ||
			tradeValues.underlyingTp2 ||
			tradeValues.underlyingTp3
		) {
			underlyingMessage = '\n\nNivåer för underliggande: \n';
		}
		if (tradeValues.underlyingEntry) {
			underlyingMessage +=
				'Entry: ' + tradeValues.underlyingEntry + ' \n';
		}
		if (tradeValues.underlyingSl) {
			underlyingMessage += 'SL: ' + tradeValues.underlyingSl + ' \n';
		}
		if (tradeValues.underlyingTp1) {
			underlyingMessage += 'TP1: ' + tradeValues.underlyingTp1 + ' \n';
		}
		if (tradeValues.underlyingTp2) {
			underlyingMessage += 'TP2: ' + tradeValues.underlyingTp2 + ' \n';
		}
		if (tradeValues.underlyingTp3) {
			underlyingMessage += 'TP3: ' + tradeValues.underlyingTp3 + ' \n';
		}

		var tradeImageLink = '';
		try {
			if (
				tradeValues.tradeImageLink &&
				tradeValues.tradeImageLink.length > 0 &&
				tradeValues.tradeImageLink.includes('http')
			) {
				tradeImageLink = '\n\nAnalys: \n' + tradeValues.tradeImageLink;
			}
		} catch (e) {
			console.error(e);
		}

		if (errorValues.length > 0) {
			alert(
				'Följande fält innehåller felaktiga värden, ex. bokstäver: \n' +
					errorValues.join('\n')
			);
			return;
		}
		if (requiredMissingValues.length > 0) {
			alert(
				'Följande fält måste fyllas i: \n' +
					requiredMissingValues.join('\n')
			);
			return;
		}
		if (wantedMissingValues.length > 0) {
			if (
				window.confirm(
					'Följande fält är inte ifyllda: \n' +
						wantedMissingValues.join('\n') +
						'\n\nÄr du säker på att du vill skicka in traden?'
				)
			) {
				console.log('Sending trade despite missing values');
			} else {
				return;
			}
		}
		if (tradeValues.size > 10) {
			alert('Size är över 10');
			return;
		}
		if (tradeValues.size > 3) {
			if (
				window.confirm(
					'Du öppnar en trade med större size än standard, är du säker på att du vill skicka in traden?'
				)
			) {
				console.log('Sending trade despite size > 3');
			} else {
				return;
			}
		}
		const currentPortfolio = tradeValues.daySwing
			? 'swingportföljen'
			: 'daytradeportföljen';
		if (Number(currentlyOpenSize) + Number(tradeValues.size) > 100) {
			console.log(Number(currentlyOpenSize) + Number(tradeValues.size));
			alert(
				'Du har redan ' +
					currentlyOpenSize +
					'ek öppet i ' +
					currentPortfolio +
					', och försöker öppna ' +
					tradeValues.size +
					' till, du kan inte öppna mer än 100'
			);
			return;
		}
		if (Number(currentlyOpenSize) + Number(tradeValues.size) > 90) {
			alert(
				'Efter denna trade öppnas kommer du ha mindre än 10 EK kvar i ' +
					currentPortfolio
			);
		}
		const result = await newTrade({
			ticker: tradeValues.instrument,
			category: tradeValues.daySwing
				? 'Swings-🚨┃swing-signal'
				: 'daytrades-🚨┃daytrade-signal',
			isin: tradeValues.isin,
			source: 'web2',
			direction: tradeValues.direction ?? 'long',
			type: tradeValues.type,
			price: String(tradeValues.entry).replaceAll(',', '.'),
			size: String(tradeValues.size).replaceAll(',', '.'),
			sl: String(tradeValues.sl).replaceAll(',', '.'),
			tp1: tradeValues.tp1
				? String(tradeValues.tp1).replaceAll(',', '.')
				: null,
			tp2: tradeValues.tp2
				? String(tradeValues.tp2).replaceAll(',', '.')
				: null,
			tp3: tradeValues.tp3
				? String(tradeValues.tp3).replaceAll(',', '.')
				: null,
			info: tradeComment
				? tradeComment + underlyingMessage + tradeImageLink
				: null,
			leverage: String(tradeValues.leverage).replaceAll(',', '.'),
		});
		console.log(result);
		if (result?.status === 201) {
			setIsNewTradeMenuOpen(false);
			alert('Trade öppnad \n' + result?.data?.data?.[0]?.name);
		} else {
			alert('Något gick fel, kontakta admin');
		}
	} catch (e) {
		console.error(e);
	}
};

export default function NewTradeMenu(props) {
	const [tableHeight, setTableHeight] = useState(400); // Default height
	const {
		isFilterMenuOpen,
		setIsFilterMenuOpen,
		isNewTradeMenuOpen,
		setIsNewTradeMenuOpen,
	} = useContext(MenuContext);
	const {
		currentlyUsedEkTotalDay,
		currentlyUsedEkTotalSwing,
		fetchedTrades,
	} = useContext(TradesContext);
	const { numTradesAllowed, currentUser } = useContext(UserContext);

	const [tradeValues, setTradeValues] = useState({
		daySwing: false,
		entry: null,
		instrument: '',
		type: '',
		isin: '',
		direction: null,
		size: 3,
		sl: null,
		tp1: null,
		tp2: null,
		tp3: null,
		leverage: null,
		avaLink: null,
		issuerLink: null,
		nordnetLink: null,
		underlying: null,
		underlyingEntry: null,
		underlyingSl: null,
		underlyingTp1: null,
		underlyingTp2: null,
		underlyingTp3: null,
		tradeImageLink: '',
	});

	const [tradeComment, setTradeComment] = useState('');

	const [tradeValuesInstrumentSearch, setTradeValuesInstrumentSearch] =
		useState('');

	const [searchResults, setSearchResults] = useState([]);

	const [avanzaChartData, setAvanzaChartData] = useState([]);

	const [tradesTakenTodayDay, setTradesTakenTodayDay] = useState();
	const [tradesTakenTodaySwing, setTradesTakenTodaySwing] = useState();

	useEffect(() => {
		const today = new Date().toISOString().slice(0, 10);
		setTradesTakenTodayDay(
			fetchedTrades.filter(
				trade =>
					trade.user.id == currentUser &&
					trade.status == 'open' &&
					trade.events[trade.events.length - 1].timestamp.includes(
						today
					) &&
					trade.category.includes('daytrades')
			).length
		);
		setTradesTakenTodaySwing(
			fetchedTrades.filter(
				trade =>
					trade.user.id == currentUser &&
					trade.status == 'open' &&
					trade.events[trade.events.length - 1].timestamp.includes(
						today
					) &&
					trade.category.includes('Swings')
			).length
		);
	}, [fetchedTrades]);

	useEffect(() => {
		// Calculate available height between header and footer
		const headerHeight = document.getElementById('header').offsetHeight;
		const footerHeight = document.getElementById('footer').offsetHeight;
		var filterMenuHeight = 0;
		if (isNewTradeMenuOpen) {
			filterMenuHeight = 68; //document.getElementById('filter-menu').offsetHeight; //TODO: dumt system, men funkar, hitta height på filtermenyn manuellt
		}
		const availableHeightTmp =
			window.innerHeight - headerHeight - footerHeight - filterMenuHeight;

		// Set the height of the table dynamically
		setTableHeight(availableHeightTmp);
	}, [isNewTradeMenuOpen]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (tradeValuesInstrumentSearch?.length > 0) {
				console.log(
					'tradeValues.instrument changed to: ' +
						tradeValuesInstrumentSearch
				);
				async function searchSymbolsTmp(searchString) {
					const result = await searchSymbols(searchString);
					console.log(result);
					setSearchResults(result);
				}
				searchSymbolsTmp(tradeValuesInstrumentSearch);
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [tradeValuesInstrumentSearch]);

	useEffect(() => {
		if (tradeValues.instrument) {
			console.log('tradeValues.instrument changed to: ', tradeValues);
			const fetchLiveData = async () => {
				const isin = searchResults.find(
					obj => obj.name === tradeValues.instrument
				).isin;
				const res = await productPriceLive(isin);
				if (!res?.success) {
					console.log('res.success: ', res.success);
					setTradeValues({
						...tradeValues,
						entry: null,
						isin: '',
						direction: null,
						leverage: null,
						avaLink: null,
						issuerLink: null,
						nordnetLink: null,
						underlying: null,
						tradeImageLink: '',
					});
				}
				const selectedTrade = res.data;
				console.log(
					'selected trade frin prodyctPriceLive: ',
					selectedTrade
				);
				if (selectedTrade) {
					setTradeValues({
						...tradeValues,
						entry:
							selectedTrade.price?.ask ??
							selectedTrade.price?.bid ??
							0,
						isin: selectedTrade.isin,
						direction: selectedTrade.direction,
						leverage: selectedTrade.leverage,
						avaLink: selectedTrade.links?.avanza,
						issuerLink: selectedTrade.links?.issuer,
						nordnetLink: selectedTrade.links?.nordnet,
						underlying: selectedTrade.underlyings?.[0]?.name,
						tradeImageLink: '',
					});
				} else {
					setTradeValues({
						...tradeValues,
						entry: null,
						isin: '',
						direction: null,
						leverage: null,
						avaLink: null,
						issuerLink: null,
						nordnetLink: null,
						underlying: null,
						tradeImageLink: '',
					});
				}
			};
			fetchLiveData();
		}
	}, [tradeValues.instrument]);

	useEffect(() => {
		var selectedTradeFG = {};
		if (tradeValues.instrument) {
			selectedTradeFG = searchResults.find(
				obj => obj.name === tradeValues.instrument
			);
		}
		if (selectedTradeFG.links?.avanza) {
			const orderBookId = selectedTradeFG.links?.avanza?.split('/')[6];
			console.log('orderBookId: ' + orderBookId);
			var timePeriod = 'today';
			var resolution = 'five_minutes';
			if (tradeValues.daySwing) {
				timePeriod = 'one_month';
				resolution = 'hour';
			} else if (!tradeValues.daySwing) {
				timePeriod = 'one_week';
				resolution = 'ten_minutes';
			}
			getAvanzaChartDataViaProxy(
				orderBookId,
				timePeriod,
				resolution,
				setAvanzaChartData
			);
		}
	}, [tradeValues.instrument]);

	const instrumentTypes = [
		{ name: 'Aktiebolag', value: 'stocks' },
		{ name: 'Råvaror', value: 'commodities' },
		{ name: 'Index', value: 'indices' },
		{ name: 'Valutor', value: 'currencies' },
		{ name: 'Krypto', value: 'crypto' },
	];

	return (
		<TableContainer
			sx={{
				width: '100%',
				maxHeight: tableHeight - 4,
				scrollbarWidth: 'thin',
			}}
			className="small-scrollbar"
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={{ backgroundColor: 'rgb(27, 30, 38)' }}>
							Ny trade
						</TableCell>
						<TableCell
							sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
						></TableCell>
						<TableCell
							sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
						></TableCell>
						<TableCell
							sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
						></TableCell>
						<TableCell
							sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
						></TableCell>
						<TableCell
							sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
						></TableCell>
						<TableCell
							sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
						></TableCell>
						<TableCell sx={{ backgroundColor: 'rgb(27, 30, 38)' }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => setIsNewTradeMenuOpen(false)}
							>
								<CloseIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
			<TableContainer sx={{ flex: 1, display: 'flex' }}>
				<Table sx={{ flex: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell
								colSpan={2}
								sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
							>
								&nbsp;
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Instrument
							</TableCell>
							<TableCell style={{ padding: 0, borderRadius: 0 }}>
								<FormControl fullWidth sx={{ borderRadius: 0 }}>
									<Select
										sx={{ borderRadius: 0 }}
										MenuProps={{ autoFocus: false }}
										size="small"
										value={tradeValues.instrument}
										onChange={e =>
											setTradeValues({
												...tradeValues,
												instrument: e.target.value,
											})
										}
										onClose={() =>
											setTradeValuesInstrumentSearch('')
										}
										renderValue={() =>
											tradeValues.instrument
										}
									>
										<ListSubheader
											style={{
												backgroundColor: '#3a3d47',
												paddingTop: 0,
												marginTop: 0,
											}}
										>
											<TextField
												style={{
													backgroundColor: '#3a3d47',
													alignItems: 'center',
													justifyContent: 'center',
													paddingTop: 16,
													marginTop: 4,
													height: 22,
												}}
												size="small"
												autoFocus
												placeholder="Sök..."
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<SearchIcon />
														</InputAdornment>
													),
												}}
												onChange={e =>
													setTradeValuesInstrumentSearch(
														e.target.value
													)
												}
												onKeyDown={e => {
													if (e.key !== 'Escape') {
														e.stopPropagation();
													}
												}}
											/>
										</ListSubheader>
										{searchResults?.map((option, i) => (
											<MenuItem
												key={i}
												value={option.name}
												style={{ fontSize: '0.875rem' }}
											>
												{option.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Typ av instrument:
							</TableCell>
							<TableCell style={{ padding: 0, borderRadius: 0 }}>
								<FormControl fullWidth sx={{ borderRadius: 0 }}>
									<Select
										sx={{
											borderRadius: 0,
											height: 22,
											fontSize: '0.875rem',
										}}
										MenuProps={{ autoFocus: false }}
										size="small"
										value={tradeValues.type}
										onChange={e =>
											setTradeValues({
												...tradeValues,
												type: e.target.value,
											})
										}
										renderValue={() =>
											instrumentTypes.find(
												e => e.value == tradeValues.type
											).name
										}
									>
										{instrumentTypes?.map((option, i) => (
											<MenuItem
												key={i}
												value={option.value}
												style={{ fontSize: '0.875rem' }}
											>
												{option.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								ISIN
							</TableCell>
							<TableCell>
								<Input
									disabled
									fullWidth
									sx={{ fontSize: '0.875rem', height: 19 }}
									placeholder={'ISIN'}
									value={tradeValues.isin}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Underliggande
							</TableCell>
							<TableCell>
								<Input
									disabled
									fullWidth
									sx={{ fontSize: '0.875rem', height: 19 }}
									placeholder={'Underliggande'}
									value={tradeValues.underlying}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Lång/Kort
							</TableCell>
							<TableCell>
								<Input
									disabled
									fullWidth
									sx={{ fontSize: '0.875rem', height: 19 }}
									placeholder={'Lång/Kort'}
									value={
										tradeValues.direction == 'long'
											? 'Lång'
											: 'Kort'
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Hävstång
							</TableCell>
							<TableCell>
								<Input
									disabled
									fullWidth
									sx={{ fontSize: '0.875rem', height: 19 }}
									placeholder={'Hävstång'}
									value={
										tradeValues.leverage
											? Number(
													tradeValues.leverage
											  ).toFixed(1)
											: null
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Entry
							</TableCell>
							<TableCell>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: '50%' }}>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'Entry'}
											value={tradeValues.entry}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													entry: e.target.value,
												})
											}
										/>
									</div>
									<div
										style={{
											width: '50%',
											borderLeft: '1px solid #bcbdc0',
											paddingLeft: '2px',
											marginLeft: '2px',
										}}
									>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'Underliggande Entry'}
											value={tradeValues.underlyingEntry}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													underlyingEntry:
														e.target.value,
												})
											}
										/>
									</div>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								SL
							</TableCell>
							<TableCell>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: '50%' }}>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'SL'}
											value={tradeValues.sl}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													sl: e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.entry,
															tradeValues.sl
														)
													)}
												</p>
											}
										/>
									</div>
									<div
										style={{
											width: '50%',
											borderLeft: '1px solid #bcbdc0',
											paddingLeft: '2px',
											marginLeft: '2px',
										}}
									>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'Underliggande SL'}
											value={tradeValues.underlyingSl}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													underlyingSl:
														e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.underlyingEntry,
															tradeValues.underlyingSl
														)
													)}
												</p>
											}
										/>
									</div>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								TP1
							</TableCell>
							<TableCell>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: '50%' }}>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'TP1'}
											value={tradeValues.tp1}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													tp1: e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.entry,
															tradeValues.tp1
														)
													)}
												</p>
											}
										/>
									</div>
									<div
										style={{
											width: '50%',
											borderLeft: '1px solid #bcbdc0',
											paddingLeft: '2px',
											marginLeft: '2px',
										}}
									>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'Underliggande TP1'}
											value={tradeValues.underlyingTp1}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													underlyingTp1:
														e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.underlyingEntry,
															tradeValues.underlyingTp1
														)
													)}
												</p>
											}
										/>
									</div>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								TP2
							</TableCell>
							<TableCell>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: '50%' }}>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'TP2'}
											value={tradeValues.tp2}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													tp2: e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.entry,
															tradeValues.tp2
														)
													)}
												</p>
											}
										/>
									</div>
									<div
										style={{
											width: '50%',
											borderLeft: '1px solid #bcbdc0',
											paddingLeft: '2px',
											marginLeft: '2px',
										}}
									>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'Underliggande TP2'}
											value={tradeValues.underlyingTp2}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													underlyingTp2:
														e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.underlyingEntry,
															tradeValues.underlyingTp2
														)
													)}
												</p>
											}
										/>
									</div>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								TP3
							</TableCell>
							<TableCell>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: '50%' }}>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'TP3'}
											value={tradeValues.tp3}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													tp3: e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.entry,
															tradeValues.tp3
														)
													)}
												</p>
											}
										/>
									</div>
									<div
										style={{
											width: '50%',
											borderLeft: '1px solid #bcbdc0',
											paddingLeft: '2px',
											marginLeft: '2px',
										}}
									>
										<Input
											fullWidth
											sx={{
												fontSize: '0.875rem',
												height: 19,
											}}
											placeholder={'Underliggande TP3'}
											value={tradeValues.underlyingTp3}
											onChange={e =>
												setTradeValues({
													...tradeValues,
													underlyingTp3:
														e.target.value,
												})
											}
											endAdornment={
												<p
													style={{
														paddingRight: '12px',
														color: '#aaa',
													}}
												>
													{String(
														calcTheoGain(
															tradeValues.underlyingEntry,
															tradeValues.underlyingTp3
														)
													)}
												</p>
											}
										/>
									</div>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Storlek
							</TableCell>
							<TableCell>
								<Input
									fullWidth
									sx={{ fontSize: '0.875rem', height: 19 }}
									placeholder={'Storlek'}
									value={tradeValues.size}
									onChange={e =>
										setTradeValues({
											...tradeValues,
											size: e.target.value,
										})
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Länk till analysbild
							</TableCell>
							<TableCell>
								<Input
									fullWidth
									sx={{ fontSize: '0.875rem', height: 19 }}
									placeholder={'Analysbild'}
									value={tradeValues.tradeImageLink}
									onChange={e =>
										setTradeValues({
											...tradeValues,
											tradeImageLink: e.target.value,
										})
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Day/Swing
							</TableCell>
							<TableCell>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'start',
									}}
								>
									<p style={{ margin: 0, padding: 0 }}>Day</p>
									<Switch
										size="small"
										checked={tradeValues.daySwing}
										onChange={e =>
											setTradeValues({
												...tradeValues,
												daySwing: e.target.checked,
											})
										}
										inputProps={{
											'aria-label': 'controlled',
										}}
									/>
									<p style={{ margin: 0, padding: 0 }}>
										Swing
									</p>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								sx={{
									borderRight: '1px solid #515151',
									textAlign: 'right',
									width: '35%',
								}}
							>
								Infotext
							</TableCell>
							<TableCell
								colSpan={2}
								rowSpan={3}
								style={{ padding: 0, margin: 0 }}
							>
								<FormControl fullWidth>
									<TextField
										id="outlined-multiline-static"
										margin="10px"
										multiline
										rows={3}
										placeholder="Kommentar"
										value={tradeComment}
										onChange={e =>
											setTradeComment(e.target.value)
										}
									/>
								</FormControl>
							</TableCell>
						</TableRow>
						<FormControl fullWidth>
							{tradeValues.daySwing == true &&
							tradesTakenTodaySwing == numTradesAllowed ? (
								<Button
									disabled
									variant="contained"
									disableElevation
									size="small"
									endIcon={<SendIcon />}
									onClick={() => {
										submitNewTrade(
											tradeValues,
											tradeComment,
											setIsNewTradeMenuOpen,
											tradeValues.daySwing == true
												? currentlyUsedEkTotalSwing
												: currentlyUsedEkTotalDay
										);
									}}
								>
									Max swings
								</Button>
							) : tradeValues.daySwing == false &&
							  tradesTakenTodayDay == numTradesAllowed ? (
								<Button
									disabled
									variant="contained"
									disableElevation
									size="small"
									endIcon={<SendIcon />}
									onClick={() => {
										submitNewTrade(
											tradeValues,
											tradeComment,
											setIsNewTradeMenuOpen,
											tradeValues.daySwing == true
												? currentlyUsedEkTotalSwing
												: currentlyUsedEkTotalDay
										);
									}}
								>
									Max daytrades
								</Button>
							) : (
								<Button
									variant="contained"
									disableElevation
									size="small"
									endIcon={<SendIcon />}
									onClick={() => {
										submitNewTrade(
											tradeValues,
											tradeComment,
											setIsNewTradeMenuOpen,
											tradeValues.daySwing == true
												? currentlyUsedEkTotalSwing
												: currentlyUsedEkTotalDay
										);
									}}
								>
									Öppna trade
								</Button>
							)}
						</FormControl>
					</TableBody>
				</Table>
			</TableContainer>

			<TableContainer
				sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}
			>
				<Table sx={{ flex: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell
								colSpan={2}
								sx={{ backgroundColor: 'rgb(27, 30, 38)' }}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'start',
										gap: 4,
									}}
								>
									<p style={{ margin: 0, padding: 0 }}>
										Länk till instrumentet:
									</p>
									<a
										href={tradeValues.avaLink ?? '#'}
										style={{
											margin: 0,
											padding: 0,
											color: tradeValues.avaLink
												? 'rgba(255,255,255,0.87)'
												: 'rgba(255,255,255,0.38)',
										}}
									>
										Avanza
									</a>
									<a
										href={tradeValues.issuerLink ?? '#'}
										style={{
											margin: 0,
											padding: 0,
											color: tradeValues.issuerLink
												? 'rgba(255,255,255,0.87)'
												: 'rgba(255,255,255,0.38)',
										}}
									>
										Utfärdare
									</a>
									<a
										href={tradeValues.nordnetLink ?? '#'}
										style={{
											margin: 0,
											padding: 0,
											color: tradeValues.nordnetLink
												? 'rgba(255,255,255,0.87)'
												: 'rgba(255,255,255,0.38)',
										}}
									>
										Nordnet
									</a>
								</div>
							</TableCell>
						</TableRow>
					</TableHead>
					{avanzaChartData.length > 10 ? (
						<LinechartComponent
							data={avanzaChartData}
							levels={[
								{
									name: 'Entry',
									price: tradeValues.entry,
									color: 'rgba(255,255,0,0.38)',
								},
								{
									name: 'SL',
									price: tradeValues.sl,
									color: 'rgba(255,0,0,0.38)',
								},
								{
									name: 'TP1',
									price: tradeValues.tp1,
									color: 'rgba(255,255,255,0.38)',
								},
								{
									name: 'TP2',
									price: tradeValues.tp2,
									color: 'rgba(255,255,255,0.38)',
								},
								{
									name: 'TP3',
									price: tradeValues.tp3,
									color: 'rgba(255,255,255,0.38)',
								},
							]}
						></LinechartComponent>
					) : null}
				</Table>
			</TableContainer>
		</TableContainer>
	);
}
